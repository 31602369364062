import {
  BrandType,
  Button,
  Flex,
  Form,
  InlineAlert,
  ModalBody,
  ModalFooter,
  object,
  string,
  TextField,
} from '@appliedsystems/applied-design-system';
import React, { useCallback, useMemo, useState } from 'react';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';
import { Translation } from '../../../localization/translations';
import styles from './LogInModal.module.scss';

const makeEnterEmailStepSchema = (t: (key: keyof Translation) => string) =>
  object({
    email: string().email(t('ERROR_EMAIL_INVALID')).required(t('ERROR_EMAIL_REQUIRED')),
  });

export type LogInFormData = {
  email: string;
};

type Props = {
  onSubmit: (data: LogInFormData) => Promise<void>;
  onCancel: () => void;
  onCreateAccountClick: () => void;
};
export const LogInForm = ({ onCancel, onSubmit, onCreateAccountClick }: Props) => {
  const { t } = usePaymentsTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schema = useMemo(() => makeEnterEmailStepSchema(t), []);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (values: LogInFormData) => {
      setLoading(true);

      await onSubmit(values);

      setLoading(false);
    },
    [onSubmit],
  );

  return (
    <Flex className={styles.form}>
      <Form schema={schema} onSubmit={handleSubmit}>
        <ModalBody>
          <div className={styles.formGroup}>
            <p>{t('ENTER_YOUR_EMAIL')}</p>
            <div className={styles.fullWidth}>
              <TextField name="email" label={t('EMAIL')} />
            </div>
            <p>
              {t('DONT_HAVE_ACCOUNT')}{' '}
              <Button type="link" onClick={onCreateAccountClick}>
                {t('CREATE_ACCOUNT')}
              </Button>
            </p>
          </div>
          <InlineAlert type={BrandType.Info} closeable={false}>
            {t('LOGIN_MODAL_TO_RECEIVE_CODE')}{' '}
            <Button type="link" onClick={onCreateAccountClick}>
              {t('CREATE_ACCOUNT')}
            </Button>
            {'. '}
            {t('DONT_RECEIVE_VERIFICATION_CODE')}
          </InlineAlert>
        </ModalBody>
        <ModalFooter>
          <Button type="tertiary" onClick={onCancel}>
            {t('CANCEL')}
          </Button>
          <Button type="primary" className="ml-100" testId="loginButton" submit isLoading={loading}>
            {t('NEXT')}
          </Button>
        </ModalFooter>
      </Form>
    </Flex>
  );
};
