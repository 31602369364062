import {
  Col,
  Combobox,
  Icon,
  Row,
  Tooltip,
  useFieldSchemaName,
  useFormContext,
} from '@appliedsystems/applied-design-system';
import React, {
  ComponentProps,
  ComponentPropsWithoutRef,
  CSSProperties,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
} from 'react';
import { useSearchParams } from 'react-router-dom';

type FlexProps = PropsWithChildren<
  Pick<CSSProperties, 'alignItems' | 'justifyContent' | 'flexBasis' | 'flexGrow' | 'flexWrap' | 'gap'> & {
    className?: string | undefined;
    style?: CSSProperties | undefined;
  }
>;
const FlexDiv = ({ className, children, style, ...props }: FlexProps & Pick<CSSProperties, 'flexDirection'>) => {
  return (
    <div style={{ ...style, ...props, display: 'flex' }} className={className}>
      {children}
    </div>
  );
};
export const FlexRow = (props: FlexProps) => <FlexDiv {...props} flexDirection="row" />;
export const FlexCol = (props: FlexProps) => <FlexDiv {...props} flexDirection="column" />;

// makes a new component like `C`, but with any `defaultProps` already included, to reduce repetition
export const wrapProps =
  <PC, PDefault extends Partial<PC>, PRemaining = Partial<PDefault> & Omit<PC, keyof PDefault>>(
    C: React.FunctionComponent<PC>,
    defaultProps?: PDefault,
  ) =>
  (props: PropsWithChildren<PRemaining>) =>
    <C {...{ ...defaultProps, ...(props as any) }} />;

// helper function to wrap a component in a Row+Col at once
// if there are multiple children, it will automatically make a Col for each
//  and if there is no `xs` prop, it'll auto-calculate it based on the number of children
export const RowCol = ({ children, ...props }: ComponentProps<typeof Col>) => (
  <Row>
    {Array.isArray(children) ? (
      children.map((c) => (
        <Col {...props} xs={'xs' in props ? props.xs : 12 / children.length}>
          {c}
        </Col>
      ))
    ) : (
      <Col {...props} xs={'xs' in props ? props.xs : 12}>
        {children}
      </Col>
    )}
  </Row>
);

// allows easy access to a query param named `param`
export const useSearchParam = <T extends string | undefined>(
  param: string,
  defaultValue?: T,
): [T extends string ? string : string | undefined, (value: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = searchParams.get(param) || defaultValue;
  const setValue = useCallback(
    (newValue: string) => {
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        if (newValue) params.set(param, newValue);
        else params.delete(param);
        return params;
      });
    },
    [param, setSearchParams],
  );

  return [value!, setValue];
};

// helper for displaying a combobox which just wants to display a single field in the dropdown and save a single field to the ADS schema
// displayField: the name of the field in `items` that should be shown in the dropdown (overrides `columns`)
// itemsIdField: the field to save to the schema (default 'id')
export const ComboboxObject = ({
  displayField,
  ...props
}: {
  displayField?: string;
} & Exclude<ComponentPropsWithoutRef<typeof Combobox>, 'onChange'>) => {
  const name = useFieldSchemaName(props.name);
  const { setValue } = useFormContext();
  return (
    <Combobox
      {...props}
      onChange={(e) => {
        // will be undefined if there's no form
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        setValue?.(name, e.target.value[props.itemsIdField ?? 'id'], {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
        props.onChange?.(e);
      }}
      columns={displayField ? [{ name: displayField }] : props.columns}
    />
  );
};

export const InfoTooltip: FC<{ helpText: string }> = ({ helpText }) => (
  <Tooltip content={helpText} trigger="mouseenter click">
    <Icon icon="ICircleIcon" size={16} onClick={(evt: React.SyntheticEvent) => evt.stopPropagation()} />
  </Tooltip>
);

// helper function to work around ADS limitation not allowing access to form context in same component that declares the Form
export const FormDirtyWrapper = ({
  setFormIsDirty,
  children,
}: PropsWithChildren<{
  setFormIsDirty: (isDirty: boolean) => void;
}>) => {
  const { formState } = useFormContext();
  useEffect(() => {
    setFormIsDirty(Object.keys(formState.dirtyFields).length > 0);
  }, [formState, setFormIsDirty]);
  return <>{children}</>;
};
