import { ValidatedEpicAccountRequest } from '@appliedsystems/payments-core';
import { useState } from 'react';
import { createContainer } from 'unstated-next';

export const useValidEpicAccount = () => {
  const [lastValidatedEpicClient, setLastValidatedEpicClient] = useState<ValidatedEpicAccountRequest>();
  return {
    lastValidatedEpicClient,
    setLastValidatedEpicClient,
  };
};
export const ValidEpicAccountStore = createContainer(useValidEpicAccount);
export const useValidEpicAccountStore = () => {
  return ValidEpicAccountStore.useContainer();
};
