import { Accordion, BrandType, Col, InlineAlert, Row } from '@appliedsystems/applied-design-system';
import { PaymentMethod, TransactionType } from '@appliedsystems/payments-core';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHppAccordion } from '../../hooks/useAccordionState';
import { useIsEligibleForFlowV2 } from '../../hooks/useIsEligibleforHppCheckoutUi';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { getDomain, getSubdomain } from '../../util/util';
import { AccountInformationForm } from '../AccountInformationForm/AccountInformationForm';
import { ConfirmationPage } from '../ConfirmationPage/ConfirmationPage';
import { PremiumFinanceConfirmationPage } from '../ConfirmationPage/PremiumFinanceConfirmationPage';
import { Copyright } from '../Copyright/Copyright';
import { ErrorAlert } from '../ErrorAlert/ErrorAlert';
import { Layout } from '../Layout/Layout';
import { PaymentMethodContainer } from '../PaymentMethodContainer';
import { PolicyInformationForm } from '../PolicyInformationForm/PolicyInformationForm';
import { SummaryCard } from '../SummaryCard/SummaryCard';
import { HppStep, PayBySelection } from './enums';
import classes from './HostedPaymentPageContainer.module.scss';
import { useHppDataStore } from './useHppData';
import { useHppSessionStore } from './useHppSession';

const { ACCOUNT_INFORMATION, POLICY_INFORMATION, PAYMENT_METHOD, DOWNPAYMENT } = HppStep;
const hppSteps = [ACCOUNT_INFORMATION, POLICY_INFORMATION, PAYMENT_METHOD, DOWNPAYMENT];

export const HostedPaymentContainer = (): React.ReactElement => {
  // Hooks
  const { t } = usePaymentsTranslation();
  const { data: agencyDetails, errorMessage } = useAgencyDetailsStore();
  const { setHppSession, hppSession } = useHppSessionStore();
  const { setPaymentMethodConfig, setRetrievedInvoices, setSelectedInvoices, setHppData, hppData } = useHppDataStore();
  const [redirectedUrl, setRedirectedUrl] = useState('');
  const [sourceUrl, setSourceUrl] = useState('');

  const location = useLocation();

  useIsEligibleForFlowV2(agencyDetails?.tenantId, '/flow/hpp');

  // Accordion stuff
  const { accordionStates, updateAccordions, checkDependencies } = useHppAccordion<
    typeof HppStep,
    'ACCOUNT_INFORMATION' | 'POLICY_INFORMATION' | 'PAYMENT_METHOD' | 'DOWNPAYMENT'
  >({
    ACCOUNT_INFORMATION: {
      isOpen: true,
      isValid: false,
      dependsOn: [],
    },
    POLICY_INFORMATION: {
      isOpen: false,
      isValid: false,
      dependsOn: [HppStep.ACCOUNT_INFORMATION],
    },
    PAYMENT_METHOD: {
      isOpen: false,
      isValid: false,
      dependsOn: [HppStep.ACCOUNT_INFORMATION, HppStep.POLICY_INFORMATION],
    },
    DOWNPAYMENT: {
      isOpen: false,
      isValid: false,
      dependsOn: [HppStep.ACCOUNT_INFORMATION, HppStep.POLICY_INFORMATION, HppStep.PAYMENT_METHOD],
    },
  });

  // Effects
  useEffect(() => {
    setPaymentMethodConfig({
      [PaymentMethod.Ach]: {
        allowed: agencyDetails?.achEnabled ?? false,
        fee: hppData.overrideAchFee ?? hppSession?.feeAch ?? agencyDetails?.achFeeAmount ?? 0.0,
      },
      [PaymentMethod.Card]: {
        allowed: agencyDetails?.creditEnabled ?? false,
        fee: hppData.overrideCcFee ?? hppSession?.creditFeePercent ?? agencyDetails?.creditFeePercent ?? 0.0,
      },
    });
  }, [agencyDetails, hppData.overrideAchFee, hppData.overrideCcFee, setPaymentMethodConfig, hppSession]);

  useEffect(() => {
    if (agencyDetails && agencyDetails.hppUrlRedirect) {
      Cookies.set('sourceUrl', window.location.href, {
        domain: getSubdomain(new URL(agencyDetails.hppUrlRedirect).hostname)
          ? `.${getDomain(new URL(agencyDetails.hppUrlRedirect).hostname)}`
          : new URL(agencyDetails.hppUrlRedirect).hostname,
      });
      window.location.href = agencyDetails.hppUrlRedirect;
    }
  }, [agencyDetails]);

  useEffect(() => {
    if (Cookies.get('sourceUrl')) {
      setSourceUrl(Cookies.get('sourceUrl') || '');
      setRedirectedUrl(window.location.href);
      Cookies.remove('sourceUrl', {
        domain: getSubdomain(new URL(Cookies.get('sourceUrl') || '').hostname)
          ? `.${getDomain(new URL(Cookies.get('sourceUrl') || '').hostname)}`
          : new URL(Cookies.get('sourceUrl') || '').hostname,
      });
    }
  }, [location]);

  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const isDownPayment = accordionStates[DOWNPAYMENT].isOpen && accordionStates[PAYMENT_METHOD].isValid;

  // Since all accordions behave similarly, run a function
  // to standardize what's passed when the components render
  const renderHppStep = (hppStep: HppStep, componentToRender: React.ReactNode) => {
    const stepIndex = hppSteps.findIndex((e) => e === hppStep);

    return (
      <div
        className={`${classes.accordion} ${accordionStates[hppStep].isValid ? classes.accordionSuccess : ''}`}
        key={hppStep}
      >
        <Accordion
          title={`${stepIndex + 1}. ${
            hppStep === PAYMENT_METHOD && hppData.pf?.quote?.quoteId ? t('PAYMENT_OPTIONS') : t(hppStep)
          }`}
          open={accordionStates[hppStep]?.isOpen && !!agencyDetails}
          onOpenChange={(nextState) => {
            updateAccordions({
              [hppStep]: {
                isOpen: nextState === false ? nextState : checkDependencies(hppStep),
                isValid: accordionStates[hppStep].isValid,
              },
              ...(hppStep === PAYMENT_METHOD &&
                nextState && {
                  [DOWNPAYMENT]: {
                    isOpen: false,
                    isValid: false,
                  },
                }),
              ...(hppStep === DOWNPAYMENT &&
                nextState && {
                  [PAYMENT_METHOD]: {
                    isOpen: false,
                    isValid: accordionStates[PAYMENT_METHOD].isValid,
                  },
                }),
            });
          }}
          testId={`${hppStep.toLowerCase()}-accordion`}
        >
          <Row className={classes.accordionContent}>
            <Col xs={12}>{componentToRender}</Col>
          </Row>
        </Accordion>
      </div>
    );
  };

  // Passing the components as the second parameter
  // directly without abstracting it into a function
  // guarantees a memory reference to the component
  const accordions = (
    <>
      {redirectedUrl && sourceUrl && (
        <InlineAlert type={BrandType.Warning}>
          {t('REDIRECT_URL_DISCLAIMER', undefined, {
            redirectedUrl,
            sourceUrl,
          } as any)}
        </InlineAlert>
      )}
      {/* Step 1. Determine payment workflow, collect and validate user info */}
      {renderHppStep(
        ACCOUNT_INFORMATION,
        <AccountInformationForm
          onDataValidated={() => {
            updateAccordions({
              [ACCOUNT_INFORMATION]: {
                isValid: true,
                isOpen: false,
              },
              [POLICY_INFORMATION]: {
                isValid: false,
                isOpen: true,
              },
            });
          }}
          onDataChange={() => {
            updateAccordions({
              [ACCOUNT_INFORMATION]: {
                isValid: false,
                isOpen: true,
              },
            });

            setHppData({
              paymentFee: 0,
              paymentMethod: undefined,
              paymentTotal: 0,
              paymentAmount: 0,
            });

            setRetrievedInvoices([]);
            setSelectedInvoices([]);
            setHppSession(undefined);
          }}
        />,
      )}
      {/* Step 2. Gather information about the payment for either single amount or invoice */}
      {renderHppStep(
        POLICY_INFORMATION,
        <PolicyInformationForm
          onBack={() => {
            updateAccordions({
              [ACCOUNT_INFORMATION]: {
                isOpen: true,
              },
              [POLICY_INFORMATION]: {
                isOpen: false,
              },
            });
          }}
          onDataValidated={() => {
            updateAccordions({
              [POLICY_INFORMATION]: {
                isValid: true,
                isOpen: false,
              },
              [PAYMENT_METHOD]: {
                isValid: false,
                isOpen: true,
              },
            });
          }}
          onDataChange={() => {
            updateAccordions({
              [POLICY_INFORMATION]: {
                isValid: false,
              },
            });

            setHppData({
              paymentFee: 0,
              paymentMethod: undefined,
              paymentTotal: 0,
            });
          }}
        />,
      )}
      {renderHppStep(
        PAYMENT_METHOD,
        <PaymentMethodContainer
          onPaymentSuccess={useCallback(() => setPaymentSucceeded(true), [])}
          onDataValidated={() => {
            if (hppData.paymentOption === 'PREMIUM_FINANCE') {
              updateAccordions({
                [PAYMENT_METHOD]: {
                  isValid: true,
                  isOpen: false,
                },
                [DOWNPAYMENT]: {
                  isValid: false,
                  isOpen: true,
                },
              });
            }
          }}
          onBack={() => {
            updateAccordions({
              [POLICY_INFORMATION]: {
                isOpen: true,
              },
              [PAYMENT_METHOD]: {
                isOpen: false,
              },
              [DOWNPAYMENT]: {
                isOpen: false,
              },
            });
          }}
          isDownPayment={false}
        />,
      )}

      {hppData.paymentOption === 'PREMIUM_FINANCE' &&
        renderHppStep(
          DOWNPAYMENT,
          <PaymentMethodContainer
            onPaymentSuccess={() => setPaymentSucceeded(true)}
            onBack={() => {
              updateAccordions({
                [PAYMENT_METHOD]: {
                  isOpen: true,
                },
                [DOWNPAYMENT]: {
                  isOpen: false,
                },
              });
            }}
            isDownPayment={isDownPayment}
          />,
        )}
      <Copyright />
    </>
  );

  const orderSummary = <SummaryCard isConfirmation={false} />;

  // TODO: link merchant name
  const confirmationComponent =
    hppData.pf?.quote &&
    hppData.pf?.signeeInfo?.signedBase64Pfa &&
    hppData.paymentOption === TransactionType.PREMIUM_FINANCE ? (
      <PremiumFinanceConfirmationPage
        pfQuote={hppData.pf.quote}
        signedBase64Pfa={hppData.pf.signeeInfo.signedBase64Pfa}
      />
    ) : (
      <ConfirmationPage merchantName={agencyDetails?.name} />
    );

  return (
    <Layout
      leftContainer={errorMessage ? <ErrorAlert errorMessage={errorMessage} /> : accordions}
      rightContainer={orderSummary}
      showConfirmationComponent={paymentSucceeded}
      confirmationComponent={confirmationComponent}
    />
  );
};
export { PayBySelection };
