import {
  ADSChangeEvent,
  BrandType,
  Button,
  DataGrid,
  DatagridCheckboxHelpers,
  DataGridColumnType,
  Flex,
  Form,
  InlineAlert,
  PhoneField,
  phoneNumber,
  Radio,
  RadioGroup,
  SectionTypes,
  SectionV2,
  TextField,
  useNotifications,
  useToast,
} from '@appliedsystems/applied-design-system';
import {
  AutopayEnrollment as Enrollment,
  AutopayType,
  Dict,
  EpicPolicy,
  ErrorCode,
  PaymentMethod,
  StoredPaymentMethod,
  toIntlFormat,
} from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import { useMutation, useQuery } from '@tanstack/react-query';
import { format } from 'date-fns-tz';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';
import { ApiClient } from '../../api/ApiClient';
import { currencyMap } from '../../constants/constants';
import { TranslateFunc, TranslationKey, usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { Locale } from '../../store/Locale';
import { useValidEpicAccountStore } from '../../store/ValidEpicAccount';
import { getConfig } from '../../util/config';
import { ComboboxObject, FlexCol, FlexRow, InfoTooltip, RowCol } from '../../util/react';
import { useHttpWrapper } from '../../util/rest';
import { AcceptCCFeeCheckbox } from '../AcceptCCFeeCheckbox/AcceptCCFeeCheckbox';
import { ErrorAlert } from '../ErrorAlert/ErrorAlert';
import { InvoiceDataGrid, InvoiceDataGridRow, InvoiceGroup } from '../HostedPaymentPage/workflows/InvoiceDataGrid';
import { Loading } from '../Loading';
import { AddPaymentMethodModal } from '../ManageAccount/PaymentMethods/AddPaymentMethodModal';
import paymentMethodContainerClasses from '../PaymentMethodContainer/PaymentMethodContainer.module.scss';
import classes from './Autopay.module.scss';

export const cardCompanies: Dict<string> = {
  visa: 'Visa',
  mc: 'Mastercard',
  amex: 'Amex',
  discover: 'Discover',
};

const reformatYMDtoMDY = (date: string): string => {
  try {
    const [_, y, m, d] = /(\d{4})-(\d{2})-(\d{2})/.exec(date)!;
    return `${m}/${d}/${y}`;
  } catch {
    return date;
  }
};

const warningCutoff = new Date(
  new Date().getTime() + Number(getConfig('REACT_APP_AUTOPAY_WARNING_DAYS')) * 24 * 60 * 60 * 1000,
);

export const AutopayEnrollment: FC<{
  enrollment?: Enrollment;
  goBackToList: () => void;
}> = ({ enrollment, goBackToList }) => {
  const { t } = usePaymentsTranslation();
  const { locale } = Locale.useContainer();
  const currencyCode = currencyMap[locale];
  const { wrapMutation, wrapQuery } = useHttpWrapper();
  const { data: agencyDetail, isLoading: agencyDetailIsLoading } = useAgencyDetailsStore();
  const { customerUser: _customerUser, refreshCustomerUser } = useAccountManagementStore();
  const { lastValidatedEpicClient } = useValidEpicAccountStore();
  const { addToast } = useToast();
  const { addNotification } = useNotifications();

  // don't allow customerUser to change once component loads (useAccountManagementStore likes to refresh it constantly)
  const [customerUser, setCustomerUser] = useState(_customerUser);
  useEffect(() => {
    if (_customerUser && !customerUser) setCustomerUser(_customerUser);
  }, [_customerUser, customerUser]);

  //
  // account validation
  const [errorMessage, setErrorMessage] = useState<[translationKey: TranslationKey, replacements?: Dict<string>]>();
  const [client, setClient] = useState<
    | {
        clientId: string;
        lookupCode: string;
        accountName: string;
        postalCode: string;
        phoneNumber: string | null;
        overrideCcFee: number | null;
        overrideAchFee: number | null;
        merchantAccountId: number;
      }
    | undefined
  >(
    enrollment?.epicClient
      ? {
          clientId: enrollment.epicClient.epicClientId,
          lookupCode: enrollment.epicClient.epicLookupCode,
          accountName: enrollment.epicClient.epicAccountName,
          postalCode: enrollment.epicClient.postalCode,
          phoneNumber: enrollment.epicClient.phoneNumber,
          overrideCcFee: enrollment.epicClient.overrideCcFee,
          overrideAchFee: enrollment.epicClient.overrideAchFee,
          merchantAccountId: enrollment.epicClient.merchantAccount.id,
        }
      : undefined,
  );
  const [autopayType, setAutopayType] = useState(enrollment?.type);
  const clientMerchantAccount =
    customerUser?.merchantAccounts.find((ma) => ma.id === client?.merchantAccountId) ?? agencyDetail;

  const accountSchema = useMemo(
    () =>
      yup.object({
        accountNumber: yup
          .string()
          .required()
          .label(t('ACCOUNT_NUMBER'))
          .default(enrollment?.epicClient.epicLookupCode ?? getConfig('REACT_APP_DEFAULT_ACCOUNT_CODE', false)),
        phoneNumber: phoneNumber()
          .required()
          .label(t('PHONE_NUMBER'))
          .default(enrollment?.epicClient.phoneNumber ?? getConfig('REACT_APP_DEFAULT_ACCOUNT_PHONE', false) ?? ''),
        postalCode: yup
          .string()
          .required()
          .label(t('POSTAL_CODE'))
          .default(enrollment?.epicClient.postalCode ?? getConfig('REACT_APP_DEFAULT_ACCOUNT_POSTAL', false)),
      }),
    [t, enrollment],
  );

  const validateAccount = useMutation(
    wrapMutation(
      async (values: yup.InferType<typeof accountSchema>) => {
        setErrorMessage(undefined);
        return ApiClient.getInstance().validateEpicAccountCode(agencyDetail!.token, {
          accountCode: values.accountNumber,
          phoneNumber: values.phoneNumber.phoneNumber ?? undefined,
          postalCode: values.postalCode,
        });
      },
      {
        transformData: (result) => {
          switch (result.status) {
            case 'valid':
              setClient({ ...result, merchantAccountId: agencyDetail!.merchantAccountId });
              break;
            case 'rate-limit-exceeded':
              setErrorMessage(['ERROR_HPP_VALIDATION_EXCEEDED_NO_BYPASS']);
              break;
            case 'accout-code-not-found':
              // case 'account-code-not-found': // this typo is part of core --
              // FIXME: change core
              setErrorMessage(['ERROR_HPP_VALIDATION']);
              break;
            case 'invalid':
              setErrorMessage(['ERROR_HPP_VALIDATION_INTERNAL']);
              break;
            default:
              console.error('validateEpicAccountCode- unknown status: ' + result.status, result);
              setErrorMessage(['ERROR_HPP_VALIDATION_UNKNOWN']);
          }
        },
      },
    ),
    { retry: false },
  );

  const [selectedValidatedEpicClientId, setSelectedValidatedEpicClientId] = useState('unset');
  const epicClients = useMemo(
    () => [
      ...(customerUser?.validatedEpicClients
        .filter((c) => customerUser.merchantAccounts.find((ma) => ma.id === c.merchantAccount.id)?.autopayEnabled)
        .map((c) => ({
          ...c,
          displayName:
            `${c.epicLookupCode} - ${c.epicAccountName}` +
            (customerUser?.validatedEpicClients.find((c2) => c2.id !== c.id && c2.epicLookupCode === c.epicLookupCode)
              ? ` (${c.merchantAccount.brandName})`
              : ''),
        })) ?? []),
      {
        displayName: t('SELECT_NEW_ACCOUNT'),
        id: 'new',
        epicClientId: 'new',
        merchantAccount: {
          id: agencyDetail?.merchantAccountId,
        },
      },
    ],
    [customerUser?.validatedEpicClients, customerUser?.merchantAccounts, t, agencyDetail?.merchantAccountId],
  );
  // make sure selected client is valid, and if not, select best guess
  useEffect(() => {
    if (selectedValidatedEpicClientId === 'new' || epicClients.find((vec) => vec.id === selectedValidatedEpicClientId))
      return;

    const hppLastClient = epicClients.find(
      (vec) =>
        vec.merchantAccount.id === agencyDetail?.merchantAccountId &&
        vec.epicClientId === lastValidatedEpicClient?.clientId,
    );
    if (hppLastClient) setSelectedValidatedEpicClientId(hppLastClient.id);
    else {
      const hppClient = epicClients.find((vec) => vec.merchantAccount.id === agencyDetail?.merchantAccountId);
      if (hppClient) setSelectedValidatedEpicClientId(hppClient.id);
      else {
        const lastClient = epicClients.find((vec) => vec.epicClientId === lastValidatedEpicClient?.clientId);
        if (lastClient) setSelectedValidatedEpicClientId(lastClient.id);
        else if (selectedValidatedEpicClientId !== 'new') setSelectedValidatedEpicClientId('new');
      }
    }
  }, [agencyDetail?.merchantAccountId, epicClients, lastValidatedEpicClient?.clientId, selectedValidatedEpicClientId]);
  // sync client with selected validated client
  useEffect(() => {
    const selectedClient = customerUser?.validatedEpicClients.find((vec) => vec.id === selectedValidatedEpicClientId);
    if (selectedClient)
      setClient({
        accountName: selectedClient.epicAccountName,
        clientId: selectedClient.epicClientId,
        lookupCode: selectedClient.epicLookupCode,
        phoneNumber: selectedClient.phoneNumber,
        postalCode: selectedClient.postalCode,
        overrideCcFee: selectedClient.overrideCcFee,
        overrideAchFee: selectedClient.overrideAchFee,
        merchantAccountId: selectedClient.merchantAccount.id,
      });
  }, [customerUser, selectedValidatedEpicClientId]);

  //
  // policies mode
  const policies = useQuery({
    queryKey: ['autopay/policies', client?.clientId],
    queryFn: wrapQuery(() =>
      ApiClient.getInstance(clientMerchantAccount!.token).getCustomerUserPolicies(
        client!.clientId,
        new URLSearchParams({ expirationDate_after: format(warningCutoff, 'yyyy-MM-dd') }),
      ),
    ),
    enabled: !!client && autopayType === AutopayType.POLICIES,
    retry: false,
    refetchOnWindowFocus: false,
  });
  const policiesColumns = useMemo(
    () =>
      [
        {
          name: 'policyType',
          title: t('POLICY_TYPE'),
          cellRenderer: (row) => row.policyType.description,
          sortValue: (row) => row.policyType.description,
        },
        {
          name: 'policyNumber',
          title: t('POLICY_NUMBER'),
        },
        {
          name: 'expiration',
          title: t('EXPIRATION'),
          cellRenderer: (row) => reformatYMDtoMDY(row.expirationOn) || '-',
          sortValue: (row) => row.expirationOn || '-',
        },
      ] satisfies DataGridColumnType<EpicPolicy>[],
    [t],
  );
  const [selectedEpicPolicyIds, setSelectedPolicyIds] = useState(new Set<string>());
  const [checkboxesInitialized, setCheckboxInitialized] = React.useState(false);
  const checkboxes = useRef<DatagridCheckboxHelpers | null>(null);
  useEffect(() => {
    if (enrollment && checkboxesInitialized) {
      checkboxes.current?.toggleChecked(enrollment.policies.map((p) => p.epicPolicyId) as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollment, checkboxesInitialized]);

  //
  // invoices mode
  // this endpoint actually returns transactions but it also has the required invoice data that we need
  const transactions = useQuery({
    queryKey: ['autopay/invoices', client?.clientId],
    queryFn: wrapQuery(
      () => ApiClient.getInstance(clientMerchantAccount!.token).getCustomerUserInvoices(client!.clientId),
      {
        transformData: (transactions) =>
          // only show transactions that will expire 3 days in the future
          transactions
            .map(
              (t) =>
                ({
                  ...t,
                  dueDate: t.arDueDate,
                  invoiceNumber: t.invoiceNumber?.toString() || '',
                  description: t.description || '',
                } satisfies InvoiceDataGridRow),
            )
            .filter((i) => new Date(i.dueDate).getTime() > warningCutoff.getTime()),
      },
    ),
    enabled: !!client,
    retry: false,
    refetchOnWindowFocus: false,
  });
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceGroup[]>([]);

  //
  // enrollment vaidation
  const enrollmentsByClient = useQuery({
    queryKey: ['autopay/enrollments', client?.clientId],
    queryFn: wrapQuery(() => ApiClient.getInstance().getAutopayEnrollmentsByClient(client!.clientId)),
    enabled: !!client,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const enrollmentsExist = useMemo(
    () => (enrollmentsByClient.data && enrollmentsByClient.data.length > 0) ?? false,
    [enrollmentsByClient.data],
  );

  const isAccountEnrolledElsewhere = useMemo(
    () => enrollmentsByClient.data?.some((e) => e.type === AutopayType.ACCOUNT && e.id !== enrollment?.id) ?? false,
    [enrollmentsByClient.data, enrollment?.id],
  );

  const shouldDisablePolicyCheckbox = useCallback(
    (policy: EpicPolicy) => {
      const policyEnrolledOnClient =
        enrollmentsByClient.data?.some((e) =>
          e.policies?.some((p) => p.originalEpicPolicyId === policy.originalPolicyId?.toLocaleLowerCase()),
        ) ?? false;

      const policyIsInEnrollment =
        enrollment?.policies.some((p) => p.originalEpicPolicyId === policy.originalPolicyId?.toLocaleLowerCase()) ??
        false;

      // transaction validation
      const policyTransactions = transactions.data?.filter((t) => t.originalPolicyId === policy.originalPolicyId);
      const transactionIsEnrolledViaInvoice =
        policyTransactions?.some((t) =>
          enrollmentsByClient.data?.some((e) =>
            e.invoices?.some((i) => i.invoiceNumber === t.invoiceNumber?.toString()),
          ),
        ) ?? false;

      return {
        disabled: (policyEnrolledOnClient || transactionIsEnrolledViaInvoice) && !policyIsInEnrollment,
        toolTip: t('AUTOPAY_POLICY_ALREADY_ENROLLED'),
      };
    },
    [enrollmentsByClient.data, enrollment?.policies, transactions.data, t],
  );

  const shouldDisableInvoiceCheckbox = useCallback(
    (invoice: InvoiceGroup) => {
      const invoiceEnrolledOnClient =
        enrollmentsByClient.data?.some((e) => e.invoices?.some((i) => i.invoiceNumber === invoice.invoiceNumber)) ??
        false;

      const invoiceIsInEnrollment =
        enrollment?.invoices.some((i) => i.invoiceNumber === invoice.invoiceNumber) ?? false;

      // transaction validation
      const invoiceTransactions = transactions.data?.filter(
        (t) => t.invoiceNumber?.toString() === invoice.invoiceNumber,
      );
      const transactionIsEnrolledViaPolicy =
        invoiceTransactions?.some((t) =>
          enrollmentsByClient.data?.some((e) =>
            e.policies?.some((p) => p.originalEpicPolicyId === t.originalPolicyId?.toLocaleLowerCase()),
          ),
        ) ?? false;

      return {
        disabled: (invoiceEnrolledOnClient || transactionIsEnrolledViaPolicy) && !invoiceIsInEnrollment,
        toolTip: t('AUTOPAY_INVOICE_ALREADY_ENROLLED'),
      };
    },
    [enrollmentsByClient.data, enrollment?.invoices, transactions.data, t],
  );

  //
  // payment method
  const [method, setMethod] = useState(PaymentMethod.Card);
  const [selectedRecurringRef, setSelectedRecurringRef] = useState<string | undefined>(
    enrollment?.storedPaymentMethod.recurringDetailReference,
  );
  const storedMethods = useQuery({
    queryKey: ['autopay/paymentMethods'],
    queryFn: wrapQuery(() => ApiClient.getInstance().getStoredPaymentMethods(), {
      transformData: (data) =>
        ({
          [PaymentMethod.Card]: [
            ...data.cards.map((c) => ({
              ...c,
              displayName:
                (c.paymentMethodNickname ? c.paymentMethodNickname + ' (' : '') +
                (cardCompanies[c.variant!] ?? t('CARD')) +
                (c.card?.number
                  ? ' ' + t('ENDING_WITH', undefined, { lastFourDigits: c.card.number.slice(-4) } as any)
                  : '') +
                (c.paymentMethodNickname ? ')' : ''),
            })),
            { recurringDetailReference: 'new', displayName: t('ENTER_A_NEW_PAYMENT_METHOD') },
          ],
          [PaymentMethod.Ach]: [
            ...data.ach.map((c) => ({
              ...c,
              displayName:
                (c.paymentMethodNickname ? c.paymentMethodNickname + ' (' : '') +
                (c.bank?.ownerName || '') +
                (c.bank?.ownerName && c.bank.bankAccountNumber ? ' - ' : '') +
                (c.bank?.bankAccountNumber ? `*****${c.bank.bankAccountNumber.slice(-4)}` : '') +
                (c.paymentMethodNickname ? ')' : ''),
            })),
            { recurringDetailReference: 'new', displayName: t('ENTER_A_NEW_PAYMENT_METHOD') },
          ],
        } as unknown as Record<PaymentMethod, (StoredPaymentMethod & { displayName: string })[]>),
    }),
    retry: false,
    refetchOnWindowFocus: false,
  });
  const selectedMethod = useMemo(
    () =>
      storedMethods.data?.ach.find((m) => m.recurringDetailReference === selectedRecurringRef) ??
      storedMethods.data?.card.find((m) => m.recurringDetailReference === selectedRecurringRef),
    [selectedRecurringRef, storedMethods.data?.ach, storedMethods.data?.card],
  );
  useEffect(() => {
    let newMethod = method;
    if (selectedMethod?.bank && method !== PaymentMethod.Ach) newMethod = PaymentMethod.Ach;
    if (selectedMethod?.card && method !== PaymentMethod.Card) newMethod = PaymentMethod.Card;

    if (method === PaymentMethod.Card && !clientMerchantAccount?.creditEnabled) newMethod = PaymentMethod.Ach;
    else if (method === PaymentMethod.Ach && !clientMerchantAccount?.achEnabled) newMethod = PaymentMethod.Card;
    if (newMethod !== method) setMethod(newMethod);
  }, [clientMerchantAccount, method, selectedMethod]);

  const [ccFeesAccepted, setCcFeesAccepted] = useState(false);
  useEffect(() => setCcFeesAccepted(method !== PaymentMethod.Card), [method]);

  const getAutoPayEnrolledMessage = (enrollmentType: AutopayType, t: TranslateFunc): string => {
    switch (enrollmentType) {
      case AutopayType.ACCOUNT:
        return t('AUTOPAY_ENROLLED_ACCOUNT');
      case AutopayType.POLICIES:
        return t('AUTOPAY_ENROLLED_POLICIES', undefined, {
          list: policies
            .data!.filter((p) => selectedEpicPolicyIds.has(p.id))
            .map((p) => p.policyNumber)
            .join(', '),
        } as any);
      case AutopayType.INVOICES:
        return t('AUTOPAY_EROLLED_INVOICES', undefined, {
          list: selectedInvoices.map((i) => i.invoiceNumber).join(', '),
        } as any);
    }
  };

  //
  // enrollment button
  const enroll = useMutation(
    wrapMutation(
      async () => {
        return await ApiClient.getInstance().upsertAutopayEnrollment({
          enrollmentId: enrollment?.id,
          epicClient: {
            epicClientId: client!.clientId,
            epicAccountName: client!.accountName,
            epicLookupCode: client!.lookupCode,
            merchantAccountId: client!.merchantAccountId,
            postalCode: client!.postalCode,
            phoneNumber: client!.phoneNumber ?? undefined,
          },
          storedPaymentMethod: {
            recurringDetailReference: selectedMethod!.recurringDetailReference,
            method: method,
          },
          type: autopayType!,
          policies:
            autopayType === AutopayType.POLICIES
              ? policies
                  .data!.filter((p) => selectedEpicPolicyIds.has(p.id))
                  .map((p) => ({
                    epicPolicyId: p.id,
                    originalEpicPolicyId: p.originalPolicyId!,
                    policyNumber: p.policyNumber,
                    dueDate: p.expirationOn,
                    policyTypeCode: p.policyType.code,
                    policyTypeDescription: p.policyType.description,
                  }))
              : undefined,
          invoices:
            autopayType === AutopayType.INVOICES
              ? selectedInvoices.map((i) => ({
                  invoiceNumber: i.invoiceNumber,
                  dueDate: i.dueDate,
                  description: i.firstDescription ?? '',
                }))
              : undefined,
        });
      },
      {
        onUserFacingError: (err) => {
          const errorCode = err.additionalDetails?.length && err.additionalDetails[0].errorCode;
          let errorMessage: string | undefined = undefined;
          if (errorCode === ErrorCode.ReferenceMustBeUnique) errorMessage = t('ENROLLMENT_CONFLICT_ERROR');

          if (errorMessage)
            addNotification({
              type: BrandType.Error,
              title: t('ERROR'),
              time: '',
              content: errorMessage,
            });
          else throw err;
        },
      },
    ),
    {
      onSuccess: async (_response) => {
        await refreshCustomerUser();
        addToast({
          content: t('AUTOPAY_ENROLLED_MESSAGE', undefined, {
            enrollmentTypeMessage: getAutoPayEnrolledMessage(autopayType!, t),
            digits:
              selectedMethod!.bank?.bankAccountNumber?.slice(-4) ?? selectedMethod!.card?.number?.slice(-4) ?? '****',
            epicLookupCode: client!.lookupCode,
          } as any),
          type: BrandType.Success,
        });
        goBackToList();
      },
      retry: false,
    },
  );

  if (agencyDetailIsLoading || !clientMerchantAccount) return <Loading />;

  return (
    <FlexCol gap="1.5rem">
      <SectionV2 title={t('AUTOPAY_DETAILS')} description={t('AUTOPAY_SETUP_INSTRUCTIONS')} type={SectionTypes.H2}>
        {isAccountEnrolledElsewhere && (
          <Flex className="mb-150">
            <InlineAlert type={BrandType.Info} closeable={false}>
              {t('AUTOPAY_ACCOUNT_ALREADY_ENROLLED')}
            </InlineAlert>
          </Flex>
        )}
        {epicClients.length > 1 && (
          <ComboboxObject
            name="validatedEpicClient"
            items={epicClients}
            label={t('SELECT_EPIC_ACCOUNT')}
            displayField="displayName"
            className={classes.methodDropdown}
            value={selectedValidatedEpicClientId}
            onChange={(e: ADSChangeEvent) => {
              setClient(undefined);
              setSelectedValidatedEpicClientId(e.target.value.id);
            }}
          />
        )}

        {/* ACCOUNT VALIDATION FORM */}
        {selectedValidatedEpicClientId === 'new' && (
          <Form schema={accountSchema} onSubmit={(values) => validateAccount.mutate(values)}>
            <FlexCol gap="1.5rem" alignItems="start" className="mt-150">
              <FlexRow gap="2rem" flexWrap="wrap">
                <TextField name="accountNumber" label={t('ACCOUNT_NUMBER')} />
                <PhoneField name="phoneNumber" label={t('PHONE_NUMBER')} hideExt />
                <TextField name="postalCode" label={t('POSTAL_CODE')} />
              </FlexRow>
              <Button submit isLoading={validateAccount.isLoading} type={'primary'}>
                {t('NEXT')}
              </Button>
              {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
            </FlexCol>
          </Form>
        )}

        {/* TYPE RADIO BUTTONS */}
        {!!client && !isAccountEnrolledElsewhere && (
          <FlexCol gap="1.5rem" className="mt-150">
            <RadioGroup
              label={t('SET_UP_AUTO_PAY_FOR')}
              value={autopayType}
              onChange={(e: ADSChangeEvent) => setAutopayType(e.target.value)}
            >
              {clientMerchantAccount.autopayEnableAccount && (
                <Radio value={AutopayType.ACCOUNT} readOnly={enrollmentsExist}>
                  <FlexRow alignItems="center" gap="2px" className={classes.readOnlyRadio}>
                    {t('ACCOUNT')}
                    <InfoTooltip
                      helpText={enrollmentsExist ? t('AUTOPAY_ACCOUNT_ENROLL_DISABLED') : t('AUTOPAY_ACCOUNT_DESC')}
                    />
                  </FlexRow>
                </Radio>
              )}
              {clientMerchantAccount.autopayEnablePolicy && (
                <Radio value={AutopayType.POLICIES}>
                  <FlexRow alignItems="center" gap="2px">
                    {t('POLICIES')} <InfoTooltip helpText={t('AUTOPAY_POLICY_DESC')} />
                  </FlexRow>
                </Radio>
              )}
              {clientMerchantAccount.autopayEnableInvoice && (
                <Radio value={AutopayType.INVOICES}>
                  <FlexRow alignItems="center" gap="2px">
                    {t('INVOICES')} <InfoTooltip helpText={t('AUTOPAY_INVOICE_DESC')} />
                  </FlexRow>
                </Radio>
              )}
            </RadioGroup>
          </FlexCol>
        )}
      </SectionV2>

      {!isAccountEnrolledElsewhere && (
        <>
          {/* POLICIES */}
          {!!client && autopayType === AutopayType.POLICIES && (
            <SectionV2 title={t('SELECT_POLICIES')} description={t('AUTOPAY_SELECT_POLICIES')} type={SectionTypes.H3}>
              <FlexCol gap="1.5rem" alignItems="start">
                <DataGrid<EpicPolicy>
                  maxHeight={500}
                  rows={policies.data ?? [undefined]}
                  columns={policiesColumns}
                  searchable={{ enabled: true }}
                  checkbox={{
                    enabled: true,
                    hideCheckAll: (policies.data?.length ?? 0) > 50,
                    helpers: (helpers) => {
                      checkboxes.current = helpers;
                      setCheckboxInitialized(true);
                    },
                    renderer: (row) => {
                      // Was getting errors here when the table was loading since rows are set to [undefined] initially
                      if (!row) return { disabled: false };

                      return shouldDisablePolicyCheckbox(row);
                    },
                  }}
                  onCheckedRowsChange={(e) => {
                    if (e.all) setSelectedPolicyIds(new Set(policies.data!.map((p) => p.id)));
                    else if (e.none) setSelectedPolicyIds(new Set());
                    else
                      setSelectedPolicyIds((ids) => {
                        ids = new Set(ids);
                        for (const change of e.items) {
                          if (change.checked) ids.add(change.item.id);
                          if (!change.checked) ids.delete(change.item.id);
                        }
                        return ids;
                      });
                  }}
                />
              </FlexCol>
            </SectionV2>
          )}

          {/* INVOICES */}
          {!!client && autopayType === AutopayType.INVOICES && (
            <SectionV2 title={t('SELECT_INVOICES')} description={t('AUTOPAY_SELECT_INVOICES')} type={SectionTypes.H3}>
              <InvoiceDataGrid
                invoices={transactions.data ?? null}
                locale={locale}
                onChange={setSelectedInvoices}
                preSelectedInvoices={enrollment?.invoices.map((i) => i.invoiceNumber)}
                shouldDisableCheckbox={shouldDisableInvoiceCheckbox}
              />
            </SectionV2>
          )}

          {/* STORED PAYMENT METHODS */}
          {!!client && (
            <SectionV2 title={t('PAYMENT_METHOD')} description={t('AUTOPAY_METHOD_DESC')} type={SectionTypes.H3}>
              <FlexCol gap="1.5rem" alignItems="start">
                {clientMerchantAccount.achEnabled && clientMerchantAccount.creditEnabled && (
                  <RadioGroup
                    value={method}
                    onChange={(e: ADSChangeEvent) => {
                      setSelectedRecurringRef(undefined);
                      setMethod(e.target.value);
                    }}
                  >
                    <Radio value="card">
                      {t('CREDIT_CARD')} (
                      {((client.overrideCcFee ?? clientMerchantAccount.creditFeePercent) / 100).toFixed(1)}% {t('FEE')})
                    </Radio>
                    <Radio value="ach">
                      {t('ACH')} (
                      {toIntlFormat(
                        {
                          amount: client.overrideAchFee ?? clientMerchantAccount.achFeeAmount,
                          currencyCode,
                        },
                        locale,
                      )}{' '}
                      {t('FEE')})
                    </Radio>
                  </RadioGroup>
                )}

                {!!storedMethods.data?.[method].length && (
                  <ComboboxObject
                    className={classes.methodDropdown}
                    items={storedMethods.data[method]}
                    label={t(method === PaymentMethod.Card ? 'CREDIT_CARD' : 'ACH')}
                    name="method"
                    itemsIdField="recurringDetailReference"
                    displayField="displayName"
                    placeholder={t(method === PaymentMethod.Card ? 'SELECT_A_CARD' : 'SELECT_AN_ACCOUNT')}
                    value={selectedRecurringRef}
                    onChange={(e: ADSChangeEvent) => setSelectedRecurringRef(e.target.value.recurringDetailReference)}
                  />
                )}

                <AddPaymentMethodModal
                  onClose={() => setSelectedRecurringRef(undefined)}
                  onSuccess={async (payment) => {
                    const refetchResult = await storedMethods.refetch();
                    const updatedMethods = Object.values(refetchResult.data ?? {}).flat();
                    const newMethod =
                      updatedMethods.find((m) => m.firstPspReference === payment.pspReferenceId) ??
                      updatedMethods.at(-1);
                    setSelectedRecurringRef(newMethod?.recurringDetailReference);
                    if (newMethod?.firstPspReference !== payment.pspReferenceId)
                      datadogLogs.logger.warn(
                        `Saved new method with PSP reference ${payment.pspReferenceId} but didn't see it in the list of methods returned`,
                        { updatedMethods },
                      );
                  }}
                  paymentMethod={
                    selectedMethod?.recurringDetailReference === 'new'
                      ? method === PaymentMethod.Card
                        ? 'scheme'
                        : 'ach'
                      : null
                  }
                  paymentSessionToken={clientMerchantAccount!.token}
                />

                {method === PaymentMethod.Card && (
                  <RowCol className={paymentMethodContainerClasses.checkboxWrapper}>
                    <AcceptCCFeeCheckbox isChecked={ccFeesAccepted} onCheckboxChange={setCcFeesAccepted} />
                  </RowCol>
                )}
              </FlexCol>
            </SectionV2>
          )}
        </>
      )}

      {/* FOOTER */}
      {!!client && (
        <>
          <FlexRow gap="1.5rem" justifyContent="end">
            <Button onClick={() => goBackToList()}>{t('CANCEL')}</Button>
            <Button
              type="primary"
              isLoading={enroll.isLoading}
              onClick={() => enroll.mutate()}
              disabled={
                !autopayType ||
                enroll.isLoading ||
                !client ||
                !selectedMethod ||
                selectedMethod.recurringDetailReference === 'new' ||
                !ccFeesAccepted ||
                (autopayType === AutopayType.POLICIES && !selectedEpicPolicyIds.size) ||
                (autopayType === AutopayType.INVOICES && !selectedInvoices.length)
              }
            >
              {t(enrollment ? 'SAVE' : 'ENROLL_IN_AUTOPAY')}
            </Button>
          </FlexRow>

          <span className={classes.legal}>
            {t('AUTOPAY_LEGAL_CARD', undefined, {
              lastFourDigits: selectedMethod?.card?.number?.slice(-4) ?? '****',
            } as any)}
          </span>
        </>
      )}
    </FlexCol>
  );
};
