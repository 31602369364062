import {
  boolean,
  Button,
  FieldSkeleton,
  Flex,
  Form,
  FormMethods,
  number,
  object,
  phoneNumber,
  string,
} from '@appliedsystems/applied-design-system';
import { PhoneFieldValue } from '@appliedsystems/applied-design-system/dist/components';
import { CheckForQuoteResponse, Dict, Workflow } from '@appliedsystems/payments-core';
import { default as React, useEffect, useMemo, useState } from 'react';
import { ApiClient } from '../../api/ApiClient';
import { TranslationKey, usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { useValidEpicAccountStore } from '../../store/ValidEpicAccount';
import { getWorkflow } from '../../util/getWorkflow';
import { ErrorAlert } from '../ErrorAlert/ErrorAlert';
import { PayBySelection } from '../HostedPaymentPageContainer/HostedPaymentPageContainer';
import { HppData, RetrievedInvoices } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { AccountInformationFields } from './AccountInformationFields';

export type AccountInfoFormSchema = Pick<
  HppData,
  | 'payBy'
  | 'paymentWorkflow'
  | 'accountCode'
  | 'firstName'
  | 'lastName'
  | 'userEmail'
  | 'businessName'
  | 'postalCode'
  | 'invoiceNumber'
  | 'phoneNumber'
> & {
  bypassAccountCode: boolean;
  phoneNumber: PhoneFieldValue;
  validationAttempts: number;
};

// Should make these environment variables
// Will be addressed after PAY-2383
const RATE_LIMIT_BYPASS_ONLY_COUNT = 3;
const RATE_LIMIT_VALIDATION_REQUIRED = 5;

export const AccountInformationForm = ({
  onDataValidated,
  onDataChange,
}: {
  onDataValidated: () => void;
  onDataChange: () => void;
}) => {
  // Setup hooks
  const { t } = usePaymentsTranslation();
  const agencyDetails = useAgencyDetailsStore();
  const token = agencyDetails.data?.token;
  const { setLastValidatedEpicClient } = useValidEpicAccountStore();
  // At this point we should have agency details
  // and if we don't, no payment should be made against
  // an unvalidated and no-agency flow

  // HPP data store
  const { setRetrievedInvoices, setHppData, setPaymentMethodConfig } = useHppDataStore();

  // Component state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<[translationKey: TranslationKey, replacements?: Dict<string>]>();
  const [defaultPayByMethod, setDefaultPayByMethod] = useState(PayBySelection.NONE);

  // Account information form schema
  const formSchema = useMemo(
    () =>
      object<AccountInfoFormSchema>({
        // Control fields
        payBy: string().oneOf(Object.values(PayBySelection)).uppercase().required(),
        bypassAccountCode: boolean(),
        paymentWorkflow: number()
          .oneOf(Object.values(Workflow).map((w) => Number(w)))
          .required(),
        validationAttempts: number(),

        // Common fields
        firstName: string().label(t('FIRST_NAME')).required(),
        lastName: string().label(t('LAST_NAME')).required(),
        userEmail: string().label(t('EMAIL')).email().required(),
        businessName: string().label(t('BUSINESS_NAME')),
        postalCode: string()
          .label(t('POSTAL_CODE'))
          .when('payBy', {
            is: (payBy: PayBySelection) => payBy === PayBySelection.NONE,
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required(),
          }),
        accountCode: string()
          .label(t('ACCOUNT_NUMBER'))
          .when(['bypassAccountCode', 'payBy', 'paymentWorkflow', 'validationAttempts'], (values: any[], schema) => {
            const [bypassAccountCode, payBy, paymentWorkflow, validationAttempts] = values as [
              bypassAccountCode: boolean,
              payBy: PayBySelection,
              paymentWorkflow: Workflow,
              validationAttempts: number,
            ];

            if (
              // Single amount is the only one that should verify
              // account code bypass conditions
              payBy === PayBySelection.AMOUNT &&
              shouldAllowAccountCodeBypass(paymentWorkflow, bypassAccountCode, validationAttempts)
            )
              return schema.notRequired();

            // Also, don't require this when the PayBy is NONE
            if (payBy === PayBySelection.NONE) return schema.notRequired();

            // For everything else, this field is required
            return schema.required();
          }),

        // Single amount
        phoneNumber: phoneNumber()
          .label(t('PHONE_NUMBER'))
          .when('$payBy', {
            // The $ is here so that we can grab the value from the form context
            is: (payBy: PayBySelection) => payBy === PayBySelection.AMOUNT,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),

        // Invoice
        invoiceNumber: string()
          .label(t('INVOICE_NUMBER'))
          .when('$payBy', {
            is: (payBy: PayBySelection) => payBy === PayBySelection.INVOICE,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.optional(),
          }),
      }).default({
        payBy: defaultPayByMethod,
        bypassAccountCode: false,
      }),
    [t, defaultPayByMethod],
  );

  // Helper functions
  const shouldAllowAccountCodeBypass = (
    workflow: Workflow,
    bypassCheckboxValue: boolean,
    validationAttempts: number,
  ): boolean => {
    // Conditions under which accountCode will be an optional value
    return (
      (workflow === Workflow.SingleAmountWithOptionalBypass && bypassCheckboxValue === true) ||
      (workflow === Workflow.SingleAmountWithRateLimitBypass && validationAttempts >= RATE_LIMIT_BYPASS_ONLY_COUNT)
    );
  };

  // Use effect is the official recommended approach
  // https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
  // This will trigger invalid memory leak warnings that
  // will also get removed in the near future
  // https://github.com/reactwg/react-18/discussions/82
  useEffect(() => {
    if (!agencyDetails.data) return;
    let defaultPayByMethod = PayBySelection.NONE;
    if (agencyDetails.data.payByInvoiceEnabled) defaultPayByMethod = PayBySelection.INVOICE;
    else if (agencyDetails.data.singleAmountEnabled) defaultPayByMethod = PayBySelection.AMOUNT;

    setDefaultPayByMethod(defaultPayByMethod);
    setHppData({ payBy: defaultPayByMethod });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyDetails.data, agencyDetails.data?.payByInvoiceEnabled, agencyDetails.data?.singleAmountEnabled]);

  // In ADS' Form context a successful submission happens when
  // the data is valid according to the specified schema
  // Not to be confused with 'validated in the backend' data
  const handleSuccessfulSubmission = async (
    values: AccountInfoFormSchema,
    formMethods: FormMethods<AccountInfoFormSchema> | null,
  ) => {
    setIsSubmitting(true);
    setErrorMessage(undefined);
    try {
      if (!token) {
        setErrorMessage(['GET_HPP_SESSION_FAILED_TOKEN_MISSING']);
        return;
      }

      // Determine workflow
      const workflow = getWorkflow(values.payBy, agencyDetails.data);

      // Valid data to return
      let clientId: HppData['clientId'] = '';
      let retrievedInvoices: RetrievedInvoices = [];
      let pfQuickQuote: CheckForQuoteResponse | undefined = undefined;

      // Controls
      let dataValidatedInEpic =
        (values.payBy === PayBySelection.AMOUNT &&
          shouldAllowAccountCodeBypass(values.paymentWorkflow, values.bypassAccountCode, values.validationAttempts)) ||
        values.payBy === PayBySelection.NONE;

      if (values.payBy === PayBySelection.AMOUNT && !values.bypassAccountCode) {
        const response = await ApiClient.getInstance().validateEpicAccountCode(token, {
          accountCode: values.accountCode,
          phoneNumber: values.phoneNumber?.phoneNumber,
          postalCode: values.postalCode,
        });

        if (response.status === 'ok') {
          switch (response.data?.status) {
            case 'valid':
              dataValidatedInEpic = true;
              clientId = response.data.clientId;
              setLastValidatedEpicClient(response.data);
              break;
            case 'rate-limit-exceeded':
              if (
                workflow === Workflow.SingleAmountWithRateLimitBypass &&
                values.validationAttempts >= RATE_LIMIT_BYPASS_ONLY_COUNT
              )
                setErrorMessage(['ERROR_HPP_VALIDATION_EXCEEDED', { buttonName: t('NEXT') }]);
              else if (workflow === Workflow.SingleAmountWithOptionalBypass)
                setErrorMessage(['ERROR_HPP_VALIDATION_BYPASS_ALLOWED']);
              else setErrorMessage(['ERROR_HPP_VALIDATION_EXCEEDED_NO_BYPASS']);
              values.validationAttempts = RATE_LIMIT_VALIDATION_REQUIRED;
              break;
            case 'accout-code-not-found':
              // case 'account-code-not-found': // this typo is part of core --
              // FIXME: change core
              setErrorMessage(['ERROR_HPP_VALIDATION']);
              break;
            case 'invalid':
              setErrorMessage(['ERROR_HPP_VALIDATION_INTERNAL']);
              break;
            default:
              console.error('validateEpicAccountCode- unknown status: ' + response.data?.status, response);
              setErrorMessage(['ERROR_HPP_VALIDATION_UNKNOWN']);
          }
        } else {
          if (response.type === 'network' || response.status > 500) setErrorMessage(['ERROR_HPP_VALIDATION_NETWORK']);
          else if (response.status === 500)
            setErrorMessage(['ERROR_HPP_VALIDATION_INTERNAL', { id: response.traceId }]);
          else setErrorMessage(['ERROR_HPP_VALIDATION_UNKNOWN', { id: response.traceId }]);
        }
      }
      // If paying by invoice
      else if (values.payBy === PayBySelection.INVOICE) {
        const response = await ApiClient.getInstance().epicInvoiceLookup(
          token,
          values,
          agencyDetails.data?.multiInvoiceEnabled,
        );

        if (response.status === 'ok') {
          switch (response.data?.status) {
            case 'valid':
              dataValidatedInEpic = true;
              clientId = response.data.clientId;
              retrievedInvoices = response.data.invoices;
              pfQuickQuote = response.data.pfQuote;
              setLastValidatedEpicClient(response.data);
              break;
            case 'invalid':
            case 'account-code-not-found':
              setErrorMessage(['ERROR_HPP_VALIDATION']);
              break;
            case 'rate-limit-exceeded':
              setErrorMessage(['ERROR_HPP_VALIDATION_EXCEEDED_NO_BYPASS']);
              break;
            default:
              console.error('epicInvoiceLookup- unknown status: ' + (response.data as any)?.status, response);
              setErrorMessage(['ERROR_HPP_VALIDATION_UNKNOWN']);
          }
        } else {
          if (response.type === 'network' || response.status > 500) setErrorMessage(['ERROR_HPP_VALIDATION_NETWORK']);
          else if (response.status === 500)
            setErrorMessage(['ERROR_HPP_VALIDATION_INTERNAL', { id: response.traceId }]);
          else setErrorMessage(['ERROR_HPP_VALIDATION_UNKNOWN', { id: response.traceId }]);
        }
      }
      // If paying by None
      else if (values.payBy === PayBySelection.NONE) {
        dataValidatedInEpic = true;
        clientId = '';
        setLastValidatedEpicClient(undefined);
      }

      // Update the state
      if (dataValidatedInEpic) {
        setHppData({
          clientId,
          pf: {
            quickQuote: pfQuickQuote?.financeableInvoices?.length ? pfQuickQuote : undefined,
          },
        });
        setRetrievedInvoices(retrievedInvoices);
        onDataValidated();
      }

      // Increment attempt count
      values.validationAttempts = (values.validationAttempts ?? 0) + 1;
      formMethods?.setValue('validationAttempts', values.validationAttempts);
    } catch (err: unknown) {
      console.error('Error validating account information', err);
      setErrorMessage(['ERROR_HPP_VALIDATION_UNKNOWN']);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form debug={false} schema={formSchema} onSubmit={handleSuccessfulSubmission}>
      {/* If this is outside the form, the schema will be unmounted regardless
      of the Form setting, resulting in all values being cleared */}
      {agencyDetails.isLoading ? (
        <FieldSkeleton />
      ) : (
        <>
          <AccountInformationFields onDataChange={onDataChange} />

          {errorMessage && (
            <div className="mt-150">
              <ErrorAlert errorMessage={errorMessage} />
            </div>
          )}

          <Flex className="flex-align-end mt-150">
            <Button
              className="brandPrimaryButton"
              type="primary"
              submit
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              {t('CONTINUE_TO_POLICY_INFORMATION')}
            </Button>
          </Flex>
        </>
      )}
    </Form>
  );
};
