import { CustomerUserAccount, LoginCustomerUserAccount } from '@appliedsystems/payments-core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createContainer } from 'unstated-next';
import { ApiClient } from '../api/ApiClient';
import { useSearchParam } from '../util/react';

const useModalWindow = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  return useMemo(() => ({ isOpen, open, close }), [isOpen, open, close]);
};

export type CustomerUserData = CustomerUserAccount;

const useAccountManagement = () => {
  const [customerUser, _setCustomerUser] = useState<CustomerUserData | null>(null);
  const setCustomerUser = useCallback(
    (cu: typeof customerUser) =>
      _setCustomerUser((customerUser) => {
        if (JSON.stringify(cu) !== JSON.stringify(customerUser)) return cu;
        return customerUser;
      }),
    [],
  );
  const [openAccount, setOpenAccount] = useSearchParam('openAccount');
  const loginModal = useModalWindow();
  const createAccountModal = useModalWindow();
  const manageAccountWindow = useModalWindow();
  const lastToken = useRef<string>();
  const customerUserAccessToken = localStorage.getItem('customerUserAccessToken');

  const login = useCallback(
    async (paymentSessionToken: string, payload: LoginCustomerUserAccount) => {
      const response = await ApiClient.getInstance(paymentSessionToken).loginCustomerUserAccount(payload);

      if (response.status === 'ok' && response.data) {
        localStorage.setItem('customerUserAccessToken', response.data.accessToken);
        setCustomerUser(response.data.user);
        lastToken.current = paymentSessionToken;
      }

      return response;
    },
    [setCustomerUser],
  );

  const requestVerificationCode = useCallback(
    async (paymentSessionToken: string, email: string, onSuccess: () => void) => {
      try {
        const response = await ApiClient.getInstance(paymentSessionToken).requestCustomerUserVerificationCode({
          email,
        });

        if (response.status !== 'ok') {
          console.error('Unexpected response status', response.status);
          throw new Error('Unexpected response status');
        }

        lastToken.current = paymentSessionToken;
        onSuccess();
      } catch (error) {
        console.error('Failed to request verification code', error);
      }
    },
    [],
  );

  const logout = useCallback(() => {
    _setCustomerUser((cu) => {
      if (cu) {
        console.info('Logging out...');
      }
      return null;
    });
    localStorage.removeItem('customerUserAccessToken');
  }, []);

  const getCurrentCustomerUser = useCallback(async (paymentSessionToken: string) => {
    lastToken.current = paymentSessionToken;
    const response = await ApiClient.getInstance(paymentSessionToken).getCurrentCustomerUser();

    return response;
  }, []);
  const refreshCustomerUser = useCallback(
    async (paymentSessionToken = lastToken.current!) => {
      const response = await getCurrentCustomerUser(paymentSessionToken);
      if (response.status === 'ok' && response.data) {
        setCustomerUser(response.data);
        lastToken.current = paymentSessionToken;
      } else {
        setCustomerUser(null);
      }
      return response;
    },
    [setCustomerUser, getCurrentCustomerUser],
  );

  useEffect(() => {
    if (!customerUser && manageAccountWindow.isOpen) {
      console.warn('Customer user not found, closing account management');
      manageAccountWindow.close();
    } else if (customerUser && openAccount && !manageAccountWindow.isOpen) {
      setOpenAccount('');
      manageAccountWindow.open();
    }
    if (!customerUserAccessToken && openAccount && !loginModal.isOpen) {
      loginModal.open();
      setOpenAccount('');
    }
  }, [openAccount, customerUser, manageAccountWindow, setOpenAccount, loginModal, customerUserAccessToken]);

  return {
    loginModal,
    createAccountModal,
    manageAccountWindow,

    customerUser,

    requestVerificationCode,
    login,
    logout,
    getCurrentCustomerUser,
    refreshCustomerUser,
  };
};

export const AccountManagement = createContainer(useAccountManagement);

export const useAccountManagementStore = () => {
  return AccountManagement.useContainer();
};
