import {
  Alert,
  AlertBody,
  BrandType,
  Button,
  ButtonContainer,
  Icon,
  ModalFooter,
} from '@appliedsystems/applied-design-system';
import { useMutation } from '@tanstack/react-query';
import React, { FC } from 'react';
import { ApiClient } from '../../api/ApiClient';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { useHttpWrapper } from '../../util/rest';

export const CancelAutopayModal: FC<{
  modalOpen: boolean;
  onClose: () => void;
  enrollmentIdToCancel?: string;
}> = ({ modalOpen, onClose, enrollmentIdToCancel }) => {
  const { t } = usePaymentsTranslation();
  const { wrapMutation } = useHttpWrapper();

  const { refreshCustomerUser } = useAccountManagementStore();

  const cancel = useMutation(
    wrapMutation(async () => {
      return await ApiClient.getInstance().cancelAutopayEnrollment({
        enrollmentId: enrollmentIdToCancel!,
      });
    }),
    {
      onSuccess: async (_response) => {
        await refreshCustomerUser();
        onClose();
      },
      retry: false,
    },
  );

  if (!enrollmentIdToCancel) return null;

  return (
    <Alert
      open={modalOpen}
      title={t('CANCEL_AUTO_PAY')}
      type={BrandType.Error}
      icon={<Icon icon="ErrorIcon" size={28} />}
      onClose={onClose}
      maxWidth={530}
    >
      <AlertBody>{t('CANCEL_AUTO_PAY_CONFIRMATION')}</AlertBody>
      <ModalFooter>
        <ButtonContainer>
          <Button type="tertiary" onClick={onClose}>
            {t('CANCEL')}
          </Button>
          <Button isLoading={cancel.isLoading} onClick={() => cancel.mutate()} disabled={cancel.isLoading}>
            {t('CANCEL_AUTO_PAY')}
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Alert>
  );
};
