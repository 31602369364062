import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import {
  BrandType,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  useNotifications,
} from '@appliedsystems/applied-design-system';
import {
  CompletePaymentResponsePayload,
  CompleteStorePaymentMethodSessionRequest,
  ErrorCode,
} from '@appliedsystems/payments-core';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { ApiClient } from '../../../../api/ApiClient';
import { CheckboxRecaptcha } from '../../../../components/CheckboxRecaptcha/CheckboxRecaptcha';
import { usePaymentsTranslation } from '../../../../hooks/usePaymentsTranslation';
import { MAP_REFUSED_CODE_TO_TRANSLATION_KEY } from '../../../../reducers/useCheckoutReducer';
import { AdyenCheckoutState, initAdyenCheckout } from '../../../../util/adyen';
import styles from './AddPaymentMethodModal.module.scss';

type Props = {
  paymentMethod: 'scheme' | 'ach' | null;
  onClose: () => void;
  onSuccess: (data: CompletePaymentResponsePayload) => void;
  paymentSessionToken: string;
};

export const AddPaymentMethodModal = ({ paymentMethod, onClose, onSuccess, paymentSessionToken }: Props) => {
  const { t } = usePaymentsTranslation();
  const { addNotification } = useNotifications();

  const dropinElementRef = React.useRef<DropinElement | null>(null);
  const [recaptchaAlertOpen, setRecaptchaAlertOpen] = React.useState(false);
  const [sessionId, setSessionId] = useState<string>();
  const [adyenPaymentMethod, setAdyenPaymentMethod] = useState<AdyenCheckoutState['data']['paymentMethod']>();

  const completeStorePaymentMethodSessionMutation = useMutation({
    mutationKey: ['completeStorePaymentMethodSession'],
    mutationFn: async (req: CompleteStorePaymentMethodSessionRequest) => {
      const response = await ApiClient.getInstance(paymentSessionToken).completeStorePaymentMethodSession(req);

      if (response.status !== 'ok' || response.data?.resultCode !== 'Authorised') {
        throw response;
      }
      return response.data;
    },
    onSuccess: async (data) => {
      addNotification({
        type: BrandType.Success,
        title: t('SUCCESS'),
        time: '',
        content: t('PAYMENT_METHOD_ADDED'),
      });

      onSuccess(data);
    },
    onError: (error: any) => {
      console.error('Failed to complete store payment method session', error);
      dropinElementRef.current?.setStatus('ready');

      if (
        error?.data?.resultCode === 'Refused' &&
        error?.data?.refusalReasonCode &&
        MAP_REFUSED_CODE_TO_TRANSLATION_KEY[error?.data?.refusalReasonCode]
      ) {
        addNotification({
          type: BrandType.Error,
          title: t('ERROR'),
          time: '',
          content: t(MAP_REFUSED_CODE_TO_TRANSLATION_KEY[error?.data?.refusalReasonCode]),
        });
        return;
      }

      const errorCode = error?.data?.additionalDetails?.[0]?.errorCode as ErrorCode | undefined;
      if (errorCode === ErrorCode.RecaptchaBrowserError) {
        addNotification({
          type: BrandType.Error,
          title: t('ERROR'),
          time: '',
          content: t('RECAPTCHA_BROWSER_ERROR'),
        });
        return;
      }
      if (errorCode === ErrorCode.RecaptchaVerificationFailed) {
        addNotification({
          type: BrandType.Error,
          title: t('ERROR'),
          time: '',
          content: t('RECAPTCHA_VERIFICATION_FAILED'),
        });
        return;
      }

      addNotification({
        type: BrandType.Error,
        title: t('ERROR'),
        time: '',
        content: t('PAYMENT_METHOD_FAILED_TO_ADD'),
      });
    },
  });

  const initStorePaymentMethodPspSessionMutation = useMutation({
    mutationKey: ['initStorePaymentMethodPspSession', paymentSessionToken],
    mutationFn: async () => {
      const response = await ApiClient.getInstance(paymentSessionToken).initStorePaymentMethodPspSession();
      if (response.status !== 'ok' || !response.data) {
        throw response;
      }
      return response.data;
    },
    onSuccess: async (data) => {
      setSessionId(data.sessionId);
      dropinElementRef.current = await initAdyenCheckout('#add-payment-method', data, t, {
        showStoredPaymentMethods: false,
        showPayButton: false,
        allowPaymentMethods: paymentMethod ? [paymentMethod] : undefined,
        onSubmit: async (state, component: DropinElement) => {
          component.setStatus('loading');
          setAdyenPaymentMethod(state.data.paymentMethod);
          setRecaptchaAlertOpen(true);
        },
      });
    },
    onError: (error: any) => {
      console.error('Failed to init store payment method psp session', error);
      dropinElementRef.current?.setStatus('error');

      addNotification({
        type: BrandType.Error,
        title: t('ERROR'),
        time: '',
        content: t('PAYMENT_METHOD_FAIL_TO_INITIALIZE_FORM'),
      });
    },
  });

  const onRecaptchaSuccess = useCallback(
    (recaptchaToken: string): Promise<void> =>
      new Promise((resolve) => {
        setRecaptchaAlertOpen(false);

        completeStorePaymentMethodSessionMutation.mutate(
          {
            paymentMethod: {
              ...adyenPaymentMethod,
              type: paymentMethod as any,
            },
            sessionId: sessionId!,
            recaptchaToken,
          },
          { onSettled: () => resolve() },
        );
      }),
    [adyenPaymentMethod, completeStorePaymentMethodSessionMutation, paymentMethod, sessionId],
  );

  useEffect(() => {
    if (!paymentMethod) {
      return;
    }

    initStorePaymentMethodPspSessionMutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  return (
    <Modal open={!!paymentMethod} onClose={onClose} title={t('ADD_PAYMENT_METHOD')}>
      <ModalBody>
        <div id="add-payment-method" className={styles.modalBody}>
          {initStorePaymentMethodPspSessionMutation.isLoading && (
            <div className={styles.loading}>
              <Icon icon="SpinnerIcon" size={32} />
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="tertiary" onClick={onClose}>
          {t('CANCEL')}
        </Button>
        <Button
          type="primary"
          className="ml-100"
          onClick={() => dropinElementRef.current?.submit()}
          disabled={
            completeStorePaymentMethodSessionMutation.isLoading || initStorePaymentMethodPspSessionMutation.isLoading
          }
          isLoading={
            completeStorePaymentMethodSessionMutation.isLoading || initStorePaymentMethodPspSessionMutation.isLoading
          }
        >
          {t('SAVE')}
        </Button>
      </ModalFooter>

      <CheckboxRecaptcha
        onError={() =>
          addNotification({
            type: BrandType.Error,
            title: t('ERROR'),
            time: '',
            content: t('RECAPTCHA_VERIFICATION_FAILED'),
          })
        }
        onSuccess={onRecaptchaSuccess}
        recaptchaAlertOpen={recaptchaAlertOpen}
        action="store"
      />
    </Modal>
  );
};
