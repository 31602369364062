import { AutopayEnrollment as Enrollment } from '@appliedsystems/payments-core';
import React, { FC, useState } from 'react';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { AutopayEnrollment } from './AutopayEnrollment';
import { AutopayList } from './AutopayList';

export const Autopay: FC = () => {
  const { customerUser, refreshCustomerUser } = useAccountManagementStore();
  const [view, setView] = useState<'LIST' | 'EDIT'>('LIST');
  const [selectedEnrollment, setSelectedEnrollment] = useState<Enrollment>();

  switch (view) {
    case 'LIST':
      return (
        <AutopayList
          goToEdit={(enrollment?: Enrollment) => {
            setView('EDIT');
            setSelectedEnrollment(enrollment);
          }}
        />
      );
    case 'EDIT':
      return (
        <AutopayEnrollment
          enrollment={selectedEnrollment}
          goBackToList={() => {
            setView('LIST');
            setSelectedEnrollment(undefined);
          }}
        />
      );
  }
};
