import '@adyen/adyen-web/dist/adyen.css';
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { ADSChangeEvent, Button, Radio, RadioGroup } from '@appliedsystems/applied-design-system';
import { PaymentMethod, StoredPaymentMethod } from '@appliedsystems/payments-core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAccountManagement } from '../../hooks/useAccountManagement';
import { AdyenCheckoutState, useAdyenConfig } from '../../hooks/useAdyenConfiguration';
import { useMakePayment } from '../../hooks/useMakePayment';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { Locale } from '../../store/Locale';
import { CheckboxRecaptcha } from '../CheckboxRecaptcha/CheckboxRecaptcha';
import { ErrorAlert, ErrorMessage } from '../ErrorAlert/ErrorAlert';
import { AvailablePaymentMethod } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { useHppSessionStore } from '../HostedPaymentPageContainer/useHppSession';
import './AdyenWebComponent.scss';

type Props = {
  type: AvailablePaymentMethod;
  disablePayNowButton: boolean;
  onPaymentSuccess: (state: AdyenCheckoutState) => void;
  onBack: () => void;
  setErrorMessage: (errorMessage: ErrorMessage | undefined) => void;
  storedPaymentMethods?: StoredPaymentMethod[];
};

export const AdyenWebComponent = ({
  type,
  disablePayNowButton,
  onPaymentSuccess,
  onBack,
  setErrorMessage: _setErrorMessage,
  storedPaymentMethods,
}: Props) => {
  const { hppSession: hppSessionDetail } = useHppSessionStore();
  const { hppData, setHppData } = useHppDataStore();
  const { locale } = Locale.useContainer();
  const { data: agencyDetail } = useAgencyDetailsStore();
  const { t } = usePaymentsTranslation();
  const hppToken = agencyDetail?.token;
  const { customerUser } = useAccountManagement(agencyDetail?.appliedProductId, hppToken);

  const [selectedMethod, setSelectedMethod] = useState<string | 'new'>(); // StoredPaymentMethod.recurringDetailReference (guid)
  const paymentContainer = useRef<HTMLDivElement>(null);
  const [recaptchaAlertOpen, setRecaptchaAlertOpen] = useState(false);
  const [onRecaptchaSuccess, setOnRecaptchaSuccess] = useState<(recaptchaToken: string) => Promise<void>>();
  const adyenComponent = useRef<UIElement>();
  const endDigitsRef = useRef<string | undefined>(); // Ref for endDigits

  const { firstName, lastName, paymentTotal: total } = hppData;

  const setErrorMessage = useCallback(
    (message?: ErrorMessage) => {
      if (message) adyenComponent.current?.setStatus('error');
      _setErrorMessage(message);
    },
    [_setErrorMessage],
  );

  const { makePayment } = useMakePayment(setErrorMessage);

  const handleOnSubmit = useCallback(
    (state: AdyenCheckoutState, component?: UIElement) => {
      if (!state.isValid) {
        component?.showValidation();
        console.error('Form is invalid', state);
        return;
      }
      const _hppSessionDetail = hppSessionDetail;
      if (state.data.paymentMethod.type === 'scheme') {
        state.data.paymentMethod.holderName = `${firstName} ${lastName}`;
      }

      // Use endDigitsRef when making the payment
      if (endDigitsRef.current) {
        setHppData({ endDigits: endDigitsRef.current });
      }

      setOnRecaptchaSuccess(() => async (recaptchaToken: string) => {
        setRecaptchaAlertOpen(false);
        await makePayment(
          onPaymentSuccess,
          state.data.paymentMethod,
          recaptchaToken,
          state,
          hppData,
          state.data.storePaymentMethod,
          _hppSessionDetail,
        );
      });
      setRecaptchaAlertOpen(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstName, lastName, hppSessionDetail, onPaymentSuccess],
  );

  const { checkout, errorMessage: adyenErrorMessage } = useAdyenConfig({
    locale,
    total,
    handleOnSubmit,
  });

  useEffect(() => {
    if (!selectedMethod) return;

    let isMounted = true;
    const createCheckout = async () => {
      try {
        if (isMounted && paymentContainer.current && checkout) {
          if (adyenComponent.current) {
            checkout.remove(adyenComponent.current);
          }
          adyenComponent.current = checkout
            .create(type, {
              enableStoreDetails: !!customerUser,
              showStoreDetailsCheckbox: !!customerUser,
              onFieldValid: (field) => {
                // .endDigits is only available credit/ach numbers
                if (field.endDigits) {
                  endDigitsRef.current = field.endDigits; // Store endDigits in the ref
                }
              },
              storedPaymentMethodId: selectedMethod === 'new' ? undefined : selectedMethod,
            })
            .mount(paymentContainer.current);
        }
      } catch (err: unknown) {
        console.error(
          `failed to create+mount Adyen checkout type=${type}, customerUser?=${!!customerUser}, selectedMethod?=${!!selectedMethod}`,
          err,
        );
        setErrorMessage(['INIT_ADYEN_CHECKOUT_FAILED']);
      }
    };
    void createCheckout();
    return () => {
      isMounted = false;
    };
  }, [type, selectedMethod, checkout, customerUser, setErrorMessage]);

  // default to new card if no methods are available
  useEffect(() => {
    if (!storedPaymentMethods?.length && selectedMethod !== 'new') setSelectedMethod('new');
  }, [selectedMethod, storedPaymentMethods?.length]);

  const handleButtonClick = () => {
    if (adyenComponent.current) {
      adyenComponent.current.submit();
    }
  };

  const formatToDollars = (amount: number) => {
    const dollars = amount / 100;
    return `${dollars.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  return (
    <div className="adyen-checkout">
      {!!storedPaymentMethods?.length && (
        <RadioGroup
          className="payment-method-radio"
          label={t(type === PaymentMethod.Card ? 'SELECT_A_CARD' : 'SELECT_AN_ACCOUNT')}
          name="direction"
          direction="vertical"
          value={selectedMethod}
          onChange={(e: ADSChangeEvent) => setSelectedMethod(e.target.value)}
        >
          {storedPaymentMethods?.map((m) => (
            <Radio value={m.recurringDetailReference} key={m.recurringDetailReference}>
              <>
                {m.paymentMethodNickname ? `${m.paymentMethodNickname} (` : ''}
                {!!m.card &&
                  [m.card.holderName, m.card.number ? `**** ${m.card.number}` : ''].filter((x) => x).join('  -  ')}
                {!!m.bank &&
                  [m.bank.ownerName, m.bank.bankAccountNumber ? `*****${m.bank.bankAccountNumber.slice(-4)}` : '']
                    .filter((x) => x)
                    .join('  -  ')}
                {m.paymentMethodNickname ? `)` : ''}
              </>
            </Radio>
          ))}
          <Radio value="new">{t('ENTER_A_NEW_PAYMENT_METHOD')}</Radio>
        </RadioGroup>
      )}
      <div
        className={`${disablePayNowButton ? 'pay__button__disabled' : ''}`}
        id="payment-page"
        style={{ display: selectedMethod ? undefined : 'none' }}
      >
        <div ref={paymentContainer}></div>
        <Button type="primary" onClick={handleButtonClick} className="custom-button">
          {t('PAY')} ${formatToDollars(total)}
        </Button>
        <Button type="tertiary" onClick={onBack} className="back__button">
          {t('BACK')}
        </Button>
      </div>

      {!!adyenErrorMessage && <ErrorAlert errorMessage={adyenErrorMessage} />}

      <CheckboxRecaptcha
        onError={() => {
          setErrorMessage(['RECAPTCHA_BROWSER_ERROR_WITH_ID']);
          setRecaptchaAlertOpen(false);
        }}
        onSuccess={onRecaptchaSuccess!}
        recaptchaAlertOpen={recaptchaAlertOpen}
        action="purchase"
      />
    </div>
  );
};
