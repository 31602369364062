import { BrandType, Button, ButtonContainer, Col, Flex, InlineAlert, Row } from '@appliedsystems/applied-design-system';
import { PaymentMethod, TransactionType } from '@appliedsystems/payments-core';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { AdyenCheckoutState } from 'src/util/adyen';
import { ApiClient } from '../../api/ApiClient';
import { currencyMap } from '../../constants/constants';
import { useAccountManagement } from '../../hooks/useAccountManagement';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { Locale } from '../../store/Locale';
import { getAmountWithFees } from '../../util/getAmountWithFees';
import { AcceptCCFeeCheckbox } from '../AcceptCCFeeCheckbox/AcceptCCFeeCheckbox';
import { AdyenWebComponent } from '../AdyenWebComponent/AdyenWebComponent';
import { AmountBreakdown } from '../AmountBreakdown/AmountBreakdown';
import { Disclaimer } from '../Disclaimer/Disclaimer';
import { ErrorAlert, ErrorMessage } from '../ErrorAlert/ErrorAlert';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { PaymentMethodSelection } from '../PaymentMethodSelection/PaymentMethodSelection';
import { PaymentOptionContainer } from '../PaymentOptionContainer';
import { PaymentOptionDetails } from '../PaymentOptionDetails/PaymentOptionDetails';
import classes from './PaymentMethodContainer.module.scss';

type Props = {
  onPaymentSuccess: (state: AdyenCheckoutState) => void;
  onDataValidated?: () => void;
  onBack: () => void;
  isDownPayment: boolean;
};

export const PaymentMethodContainer = ({
  onPaymentSuccess,
  onDataValidated,
  onBack,
  isDownPayment,
}: Props): React.ReactElement => {
  const [isCCFeeAccepted, setIsCCFeeAccepted] = useState(false);
  const { data: agencyDetails } = useAgencyDetailsStore();
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();
  const [isPfaSigned, setIsPfaSigned] = useState(false);
  const { t } = usePaymentsTranslation();
  const { paymentMethodConfig, hppData, setHppData, selectedInvoices } = useHppDataStore();
  const handleCheckboxChange = (checked: boolean) => {
    setIsCCFeeAccepted(checked);
  };
  const { isCcFeeCheckboxEnabled, ccFeeThresholdAmount } = agencyDetails || {};
  const hppToken = agencyDetails?.token;
  const { isAccountManagementEnabled: isAccountManagementFlagEnabled, customerUser } = useAccountManagement(
    agencyDetails?.appliedProductId,
    hppToken,
  );
  const isAccountManagementEnabled = isAccountManagementFlagEnabled && agencyDetails?.manageMyAccountEnabled;
  const { loginModal, customerUser: user } = useAccountManagementStore();
  const { locale } = Locale.useContainer();

  const storedPaymentMethods = useQuery(['paymentMethods', customerUser?.id], async () => {
    if (!customerUser) return null;
    const response = await ApiClient.getInstance().getStoredPaymentMethods();
    if (response.status !== 'ok' || !response.data) {
      console.error(`Invalid response from server`, response);
      throw new Error(`Invalid response from server`);
    }

    return response.data;
  });

  // Determine if the fee checkbox should be shown. It is shown if:
  // - The selected payment method is a card
  // - The credit card fee checkbox feature is enabled
  // - The amount is greater than or equal to the credit card fee threshold amount (or 0 if the threshold is not defined)
  // - The credit fee is not 0

  const showFeeCheckbox =
    hppData.paymentMethod === PaymentMethod.Card &&
    isCcFeeCheckboxEnabled &&
    hppData.paymentAmount >= (ccFeeThresholdAmount ?? 0) &&
    paymentMethodConfig[PaymentMethod.Card].fee !== 0;

  // Determine if the "Pay Now" button should be disabled. It is disabled if:
  // - The fee checkbox is shown and the fee has not been accepted
  const disablePayNowButton = Boolean(showFeeCheckbox && !isCCFeeAccepted);

  useEffect(() => {
    // If we are in down payment accordion, we need to calculate the subtotal differently
    // isDownPayment here in the useEffect is because I want to rerun this useEffect when user navigates back to the payment method accordion from down payment accordion
    if (hppData.paymentOption === TransactionType.PREMIUM_FINANCE || isDownPayment) {
      if (!hppData.pf?.quote) {
        return;
      }
      const newPfSubtotal = hppData.paymentAmount - hppData.pf.quote.amountFinanced!;
      setHppData({
        pf: {
          ...hppData.pf,
          pfSubtotal: newPfSubtotal,
        },
        paymentMethod: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hppData.paymentOption, isDownPayment]);

  useEffect(() => {
    // Reset the isPfaSigned and signeeInfo state when the user changes in the dependency array
    if (isPfaSigned) {
      setIsPfaSigned(false);
      setHppData({
        pf: {
          ...hppData.pf,
          signeeInfo: undefined,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hppData.firstName,
    hppData.lastName,
    hppData.userEmail,
    hppData.businessName,
    hppData.postalCode,
    hppData.phoneNumber,
    hppData.invoiceNumber,
    hppData.accountCode,
    selectedInvoices,
  ]);

  return (
    <Flex className={classes.paymentContainer}>
      {/* This component handles both "Pay in Full" and "Pay with Financing" options. It displays the financing card if there is a quote ID and the user is not in the down payment accordion. */}
      {hppData.pf?.quote?.quoteId && !isDownPayment && (
        <PaymentOptionContainer
          pfInfo={hppData.pf}
          onPaymentOptionSelected={(paymentDetails) => {
            const paymentOption = paymentDetails.paymentOption!;
            setHppData({
              paymentOption: paymentOption,
              paymentMethod: undefined,
            });
          }}
        />
      )}
      {!hppData.pf?.quote?.quoteId && <p>{t('CHOOSE_YOUR_PAYMENT_METHOD')}</p>}

      {isDownPayment && <AmountBreakdown />}

      {/* if the flow is not premium finance or if it is in down payment accordion -  component is used here to allow the user to select a payment method */}
      {hppData.paymentOption === TransactionType.PAID_IN_FULL ||
      !hppData.pf?.quote?.quoteId ||
      (hppData.pf?.quote?.quoteId && isDownPayment) ? (
        <>
          {(hppData.paymentAmount || hppData.pf?.quote?.downPaymentAmount) && (
            <PaymentMethodSelection
              isDownPayment={isDownPayment}
              onPaymentMethodSelected={(paymentDetails) => {
                const paymentMethod = paymentDetails.paymentMethod!;
                const subtotal =
                  isDownPayment && hppData.pf?.quote
                    ? BigInt(hppData.pf.pfSubtotal!) + BigInt(hppData.pf.quote?.retainedPayments ?? 0)
                    : hppData.paymentAmount;
                const { paymentTotal, paymentFee } = getAmountWithFees(
                  paymentMethod,
                  Number(subtotal),
                  paymentMethodConfig[paymentDetails.paymentMethod!].fee,
                  currencyMap[locale],
                );
                setHppData({
                  paymentMethod,
                  paymentTotal,
                  paymentFee,
                });
              }}
            />
          )}
          <div className={classes.buttonsContainer}>
            {isAccountManagementEnabled && !customerUser && (
              <Button className={classes.paymentMethodOnFileButton} onClick={!user ? loginModal.open : undefined}>
                {t('USE_PAYMENT_METHOD_ON_FILE')}
              </Button>
            )}
            {/* This back button is displayed in the UI before the user selects a
        payment method. */}
            {!hppData.paymentMethod && (
              <Button type="tertiary" onClick={onBack}>
                {t('BACK')}
              </Button>
            )}
          </div>
          {hppData.paymentMethod && (
            <Row>
              <Col xs={12}>
                <AdyenWebComponent
                  type={hppData.paymentMethod}
                  disablePayNowButton={disablePayNowButton}
                  onPaymentSuccess={onPaymentSuccess}
                  onBack={onBack}
                  setErrorMessage={setErrorMessage}
                  storedPaymentMethods={
                    storedPaymentMethods.data?.[
                      hppData.paymentMethod === PaymentMethod.Card ? 'cards' : hppData.paymentMethod
                    ]
                  }
                />
              </Col>
            </Row>
          )}
          {showFeeCheckbox && (
            <Row>
              <Col xs={12} className={classes.checkboxWrapper}>
                <AcceptCCFeeCheckbox onCheckboxChange={handleCheckboxChange} isChecked={isCCFeeAccepted} />
              </Col>
            </Row>
          )}
          {!!errorMessage && <ErrorAlert errorMessage={errorMessage} />}
          {!!storedPaymentMethods.isError && (
            <Flex className={classes.error}>
              <InlineAlert type={BrandType.Warning} closeable={true}>
                {t('ERROR_FAILED_TO_LOAD_PAYMENT_METHODS')}
              </InlineAlert>
            </Flex>
          )}
          <Disclaimer />
        </>
      ) : (
        <>
          <PaymentOptionDetails pfInfo={hppData.pf} isPfaSigned={isPfaSigned} setIsPfaSigned={setIsPfaSigned} />
          <ButtonContainer>
            <Button type="tertiary" onClick={onBack}>
              {t('BACK')}
            </Button>
            <Button type="primary" disabled={!isPfaSigned} onClick={onDataValidated}>
              {t('CONTINUE_TO_DOWN_PAYMENT')}
            </Button>
          </ButtonContainer>
        </>
      )}
    </Flex>
  );
};
